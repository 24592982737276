import { createApp } from 'vue'
import { store } from './store'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import vfmPlugin from 'vue-final-modal'
import App from './App.vue'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(VueCookies)
app.use(vfmPlugin)
app.mount('#app')