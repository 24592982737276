<template>
	<div v-if="catalog" class="catalog" @click="goToCatalog()">
      <i class="fa-solid fa-rectangle-list"></i>&nbsp; CATALOGO
    </div>
</template>

<script>  
export default {
	name: 'CatalogLogin',
  data() {
    return {
      
    }
  },
  computed: {
    catalog: function() {
      return this.$store.state.infos.catalog
    }
  },
  methods: {
    goToCatalog() {
      this.$router.push({
        path: `/catalog`
      })
    }
  }
}
</script>

<style type="text/css" scoped>
  .catalog {
    background: var(--color-accent2);
    color: var(--color-textaccent2);
    padding: 12px 24px;
    text-align: center;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;
  }

  .catalog:hover {
    background: var(--color-accent2hover);
    color: var(--color-textaccent2hover);
  }

  @media only screen and (max-width: 599px) {
    .catalog {
      width: auto;
      margin: 0 auto;
      display: inline-block;
      position: initial;
    }
  }
</style>