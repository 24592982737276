import { createStore } from 'vuex'
import router from './router'

export const store = createStore({
  state() {
    return {
      base_url: 'https://company-core.lezione-online.it',
      token: '',
      company_id: 0,
      superadmin: false,
      company_name: '',
      company_logo: '',
      login_template: '',
      login_template_english_test: '',
      colors: '',
      infos: '',
      student_infos: ''
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setCompanyID(state, company_id) {
      state.company_id = company_id
    },
    setSuperAdmin(state, superadmin) {
      state.superadmin = superadmin
    },
    setCompanyName(state, company_name) {
      state.company_name = company_name
    },
    setCompanyLogo(state, company_logo) {
      state.company_logo = company_logo
    },
    setLoginTemplate(state, login_template) {
      state.login_template = JSON.parse(login_template)
    },
    setLoginTemplateEnglishTest(state, login_template_english_test) {
      state.login_template_english_test = JSON.parse(login_template_english_test)
    },
    setColors(state, colors) {
      state.colors = JSON.parse(colors)
    },
    setInfos(state, infos) {
      state.infos = infos
    },
    setStudentInfos(state, student_infos) {
      state.student_infos = student_infos
    }
  },
  getters: {
    getToken: state => {
      if(state.token != '')
        return window.parseJwt(state.token)
      else
        return ''
    }
  },
  actions: {
    checkToken(context) {
      let token = context.getters.getToken;
      if((token['exp'] - 30) <= Math.round(new Date().getTime() / 1000)) {
        context.commit('setToken', '');
        router.push('/');
      }
    }
  }
})