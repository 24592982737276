import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: () => import('../views/Catalog.vue')
  },
  {
    path: '/catalog/course/:course_id',
    name: 'catalog-course',
    component: () => import('../views/CatalogCourse.vue')
  },
  {
    path: '/courses',
    name: 'my-courses',
    component: () => import('../views/MyCourses.vue')
  },
  {
    path: '/webinars',
    name: 'my-webinars',
    component: () => import('../views/MyWebinars.vue')
  },
  {
    path: '/certificates',
    name: 'certificates',
    component: () => import('../views/Certificates.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/fruition/:course_id/:unit_id',
    name: 'fruition',
    component: () => import('../views/Fruition.vue')
  },
  {
    path: '/admin-courses',
    name: 'admin-courses',
    component: () => import('../views/AdminCourses.vue')
  },
  {
    path: '/admin-activation-quizzes',
    name: 'admin-activation-quizzes',
    component: () => import('../views/AdminActivationQuizzes.vue')
  },
  {
    path: '/admin-webinars',
    name: 'admin-webinars',
    component: () => import('../views/AdminWebinars.vue')
  },
  {
    path: '/admin-webinars/:webinar_id/classes',
    name: 'admin-webinar-classes',
    component: () => import('../views/AdminWebinarClasses.vue')
  },
  {
    path: '/admin-webinars/:webinar_id/classes/:webinar_class_id/units',
    name: 'admin-webinar-class-units',
    component: () => import('../views/AdminWebinarClassUnits.vue')
  },
  {
    path: '/admin-webinars/:webinar_id/classes/:webinar_class_id/units/:webinar_class_unit_id/sessions',
    name: 'admin-webinar-class-sessions',
    component: () => import('../views/AdminWebinarClassSessions.vue')
  },
  {
    path: '/admin-webinars/:webinar_id/classes/:webinar_class_id/units/:webinar_class_unit_id/sessions/:webinar_class_session_id/attendance',
    name: 'admin-webinar-class-session-attendance',
    component: () => import('../views/AdminWebinarClassSessionAttendance.vue')
  },
  {
    path: '/admin-students',
    name: 'admin-students',
    component: () => import('../views/AdminStudents.vue')
  },
  {
    path: '/admin-students/:course_id',
    name: 'admin-students-course',
    component: () => import('../views/AdminStudents.vue')
  },
  {
    path: '/admin-students/:webinar_id/:class_id',
    name: 'admin-students-webinar-class',
    component: () => import('../views/AdminStudents.vue')
  },
  {
    path: '/feedback/:course_id/:course_title',
    name: 'feedback',
    component: () => import('../views/Feedback.vue')
  },
  {
    path: '/feedback-results/:course_id/:course_title',
    name: 'feedback-results',
    component: () => import('../views/FeedbackResults.vue')
  },
  {
    path: '/activation-quiz/:activation_quiz_id',
    name: 'activation-quiz',
    component: () => import('../views/ActivationQuiz.vue')
  },
  {
    path: '/english-test/',
    name: 'english-test-login',
    component: () => import('../views/LanguageQuizLogin.vue')
  },
  {
    path: '/language-quiz/:language_quiz_id',
    name: 'language-quiz',
    component: () => import('../views/LanguageQuiz.vue')
  },
  {
    path: '/language-quiz-result/:language_quiz_user_id/:token',
    name: 'language-quiz-result',
    component: () => import('../views/LanguageQuizResult.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router
