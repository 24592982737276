<template>
  <div class="login" :style="background">

    <div class="desktop_top_left" v-if="login_template.desktop.top_left.type != 'empty'">
      <div v-if="login_template.desktop.top_left.type == 'default'">
        <SupportLogin v-if="login_template.desktop.top_left.data == 'support'"></SupportLogin>
        <CatalogLogin v-if="login_template.desktop.top_left.data == 'catalog' && catalog"></CatalogLogin>
      </div>
      <img v-if="login_template.desktop.top_left.type == 'img' && login_template.desktop.top_left.href == ''" v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.desktop.top_left.data" v-bind:style="{ width: login_template.desktop.top_left.width }" />
      <a v-else-if="login_template.desktop.top_left.type == 'img'" :href="login_template.desktop.top_left.href" target="_blank"><img v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.desktop.top_left.data" v-bind:style="{ width: login_template.desktop.top_left.width }" /></a>
    </div>

    <div class="desktop_top_right" v-if="login_template.desktop.top_right.type != 'empty'">
      <div v-if="login_template.desktop.top_right.type == 'default'">
        <SupportLogin v-if="login_template.desktop.top_right.data == 'support'"></SupportLogin>
        <CatalogLogin v-if="login_template.desktop.top_right.data == 'catalog' && catalog"></CatalogLogin>
      </div>
      <img v-if="login_template.desktop.top_right.type == 'img' && login_template.desktop.top_right.href == ''" v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.desktop.top_right.data" v-bind:style="{ width: login_template.desktop.top_right.width }" />
      <a v-else-if="login_template.desktop.top_right.type == 'img'" :href="login_template.desktop.top_right.href" target="_blank"><img v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.desktop.top_right.data" v-bind:style="{ width: login_template.desktop.top_right.width }" /></a>
    </div>

    <div class="mobile_top_1" v-if="login_template.mobile.top_1.type != 'empty'">
      <div v-if="login_template.mobile.top_1.type == 'default'">
        <SupportLogin v-if="login_template.mobile.top_1.data == 'support'"></SupportLogin>
        <CatalogLogin v-if="login_template.mobile.top_1.data == 'catalog' && catalog"></CatalogLogin>
      </div>
      <img v-if="login_template.mobile.top_1.type == 'img' && login_template.mobile.top_1.href == ''" v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.mobile.top_1.data" v-bind:style="{ width: login_template.mobile.top_1.width }" />
      <a v-else-if="login_template.mobile.top_1.type == 'img'" :href="login_template.mobile.top_1.href" target="_blank"><img v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.mobile.top_1.data" v-bind:style="{ width: login_template.mobile.top_1.width }" /></a>
      <span v-if="login_template.mobile.top_1.type == 'text'" v-html="login_template.mobile.top_1.data" v-bind:style=" { color: login_template.mobile.top_1.color } "></span>
    </div>

    <div class="mobile_top_2" v-if="login_template.mobile.top_2.type != 'empty'">
      <div v-if="login_template.mobile.top_2.type == 'default'">
        <SupportLogin v-if="login_template.mobile.top_2.data == 'support'"></SupportLogin>
        <CatalogLogin v-if="login_template.mobile.top_2.data == 'catalog' && catalog"></CatalogLogin>
      </div>
      <img v-if="login_template.mobile.top_2.type == 'img' && login_template.mobile.top_2.href == ''" v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.mobile.top_2.data" v-bind:style="{ width: login_template.mobile.top_2.width }" />
      <a v-else-if="login_template.mobile.top_2.type == 'img'" :href="login_template.mobile.top_2.href" target="_blank"><img v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.mobile.top_2.data" v-bind:style="{ width: login_template.mobile.top_2.width }" /></a>
      <span v-if="login_template.mobile.top_2.type == 'text'" v-html="login_template.mobile.top_2.data" v-bind:style=" { color: login_template.mobile.top_2.color } "></span>
    </div>

    <div class="mobile_top_3" v-if="login_template.mobile.top_3.type != 'empty'">
      <div v-if="login_template.mobile.top_3.type == 'default'">
        <SupportLogin v-if="login_template.mobile.top_3.data == 'support'"></SupportLogin>
        <CatalogLogin v-if="login_template.mobile.top_3.data == 'catalog' && catalog"></CatalogLogin>
      </div>
      <img v-if="login_template.mobile.top_3.type == 'img' && login_template.mobile.top_3.href == ''" v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.mobile.top_3.data" v-bind:style="{ width: login_template.mobile.top_3.width }" />
      <a v-else-if="login_template.mobile.top_3.type == 'img'" :href="login_template.mobile.top_3.href" target="_blank"><img v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.mobile.top_3.data" v-bind:style="{ width: login_template.mobile.top_3.width }" /></a>
      <span v-if="login_template.mobile.top_3.type == 'text'" v-html="login_template.mobile.top_3.data" v-bind:style=" { color: login_template.mobile.top_3.color } "></span>
    </div>

    <div class="login_container">

      <div class="desktop_login_top" v-if="login_template.desktop.login_top.type != 'empty'">
        <div v-if="login_template.desktop.login_top.type == 'default'">
          <SupportLogin v-if="login_template.desktop.login_top.data == 'support'"></SupportLogin>
          <CatalogLogin v-if="login_template.desktop.login_top.data == 'catalog' && catalog"></CatalogLogin>
        </div>
        <img v-if="login_template.desktop.login_top.type == 'img' && login_template.desktop.login_top.href == ''" v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.desktop.login_top.data" v-bind:style="{ width: login_template.desktop.login_top.width }" />
        <a v-else-if="login_template.desktop.login_top.type == 'img'" :href="login_template.desktop.login_top.href" target="_blank"><img v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.desktop.login_top.data" v-bind:style="{ width: login_template.desktop.login_top.width }" /></a>
        <span v-if="login_template.desktop.login_top.type == 'text'" v-html="login_template.desktop.login_top.data" v-bind:style=" { color: login_template.desktop.login_top.color } "></span>
      </div>

      <div class="login_box">
        <div class="desktop_login_box_top" v-if="login_template.desktop.login_box_top.type != 'empty'">
          <img v-if="login_template.desktop.login_box_top.type == 'img' && login_template.desktop.login_box_top.href == ''" v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.desktop.login_box_top.data" v-bind:style="{ width: login_template.desktop.login_box_top.width }" />
          <a v-else-if="login_template.desktop.login_box_top.type == 'img'" :href="login_template.desktop.login_box_top.href" target="_blank"><img v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.desktop.login_box_top.data" v-bind:style="{ width: login_template.desktop.login_box_top.width }" /></a>
          <span v-if="login_template.desktop.login_box_top.type == 'text'" v-html="login_template.desktop.login_box_top.data" v-bind:style=" { color: login_template.desktop.login_box_top.color } "></span>
        </div>
        <div v-if="reset_password" class="login_content">
          <a href="#" style="width: fit-content; margin-left: auto;" @click="reset_password = false; status = 0; status_text = ''"><i class="fas fa-arrow-left"></i> Torna indietro</a>
          <br/>
          <label>Email</label>
          <input type="text" v-model="email" v-on:keyup.enter="sendResetPassword" />
          <p class="blue">Inserisci il tuo indirizzo email e clicca su invia.</p>
          <p>Riceverai via email una password temporanea che utilizzerai per impostare una nuova password. Se non la ricevi controlla le spam.</p>
          <button v-if="!loading" @click="sendResetPassword">INVIA EMAIL</button>
          <button v-else><i class="fas fa-spinner fa-spin"></i></button>
          <div class="message" :class="[{success: status == 1}, {error: status == -1}]">{{ status_text }}</div>
        </div>
        <div v-else-if="change_password" class="login_content change_password">
          <p>Inserisci la nuova password con la quale potrai accedere alla piattaforma.</p>
          <label>Password
            <i class="fas fa-eye" v-if="!newPasswordShow" @click="newPasswordShow = !newPasswordShow"></i>
            <i class="fas fa-eye-slash" v-else @click="newPasswordShow = !newPasswordShow"></i>
          </label>
          <input v-bind:type="newPasswordShow ? 'text': 'password'" v-model="new_password" v-on:keyup.enter="changePassword" />
          <br/>
          <label>Conferma Password
            <i class="fas fa-eye" v-if="!newPasswordConfirmShow" @click="newPasswordConfirmShow = !newPasswordConfirmShow"></i>
            <i class="fas fa-eye-slash" v-else @click="newPasswordConfirmShow = !newPasswordConfirmShow"></i>
          </label>
          <input v-bind:type="newPasswordConfirmShow ? 'text': 'password'" v-model="password_confirm" v-on:keyup.enter="changePassword" />
          <br/>
          <button v-if="!loading" @click="changePassword">CONFERMA</button>
          <button v-else><i class="fas fa-spinner fa-spin"></i></button>
          <div class="message" :class="[{success: status == 1}, {error: status == -1}]">{{ status_text }}</div>
        </div>
        <div v-else class="login_content">
          <label>Email</label>
          <input type="text" v-model="email" v-on:keyup.enter="login" />
          <br/>
          <label>Password
            <i class="fas fa-eye" v-if="!passwordShow" @click="passwordShow = !passwordShow"></i>
            <i class="fas fa-eye-slash" v-else @click="passwordShow = !passwordShow"></i>
          </label>
          <input v-bind:type="passwordShow ? 'text': 'password'" v-model="password" v-on:keyup.enter="login" />
          <br/>
          <a href="#" @click="reset_password = true">Password dimenticata?</a>
          <br/>
          <button v-if="!loading" @click="login">ACCEDI</button>
          <button v-else><i class="fas fa-spinner fa-spin"></i></button>
          <div class="message" :class="[{success: status == 1}, {error: status == -1}]">{{ status_text }}</div>
        </div>
      </div>
    </div>

    <div class="desktop_bottom_left" v-if="login_template.desktop.bottom_left.type != 'empty'">
      <div v-if="login_template.desktop.bottom_left.type == 'default'">
        <SupportLogin v-if="login_template.desktop.bottom_left.data == 'support'"></SupportLogin>
        <CatalogLogin v-if="login_template.desktop.bottom_left.data == 'catalog' && catalog"></CatalogLogin>
      </div>
      <img v-if="login_template.desktop.bottom_left.type == 'img' && login_template.desktop.bottom_left.href == ''" v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.desktop.bottom_left.data" v-bind:style="{ width: login_template.desktop.bottom_left.width }" />
      <a v-else-if="login_template.desktop.bottom_left.type == 'img'" :href="login_template.desktop.bottom_left.href" target="_blank"><img v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.desktop.bottom_left.data" v-bind:style="{ width: login_template.desktop.bottom_left.width }" /></a>
    </div>

    <div class="desktop_bottom_right" v-if="login_template.desktop.bottom_right.type != 'empty'">
      <div v-if="login_template.desktop.bottom_right.type == 'default'">
        <SupportLogin v-if="login_template.desktop.bottom_right.data == 'support'"></SupportLogin>
        <CatalogLogin v-if="login_template.desktop.bottom_right.data == 'catalog' && catalog"></CatalogLogin>
      </div>
      <img v-if="login_template.desktop.bottom_right.type == 'img' && login_template.desktop.bottom_right.href == ''" v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.desktop.bottom_right.data" v-bind:style="{ width: login_template.desktop.bottom_right.width }" />
      <a v-else-if="login_template.desktop.bottom_right.type == 'img'" :href="login_template.desktop.bottom_right.href" target="_blank"><img v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.desktop.bottom_right.data" v-bind:style="{ width: login_template.desktop.bottom_right.width }" /></a>
    </div>

    <div class="mobile_bottom_1" v-if="login_template.mobile.bottom_1.type != 'empty'">
      <div v-if="login_template.mobile.bottom_1.type == 'default'">
        <SupportLogin v-if="login_template.mobile.bottom_1.data == 'support'"></SupportLogin>
        <CatalogLogin v-if="login_template.mobile.bottom_1.data == 'catalog' && catalog"></CatalogLogin>
      </div>
      <img v-if="login_template.mobile.bottom_1.type == 'img' && login_template.mobile.bottom_1.href == ''" v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.mobile.bottom_1.data" v-bind:style="{ width: login_template.mobile.bottom_1.width }" />
      <a v-else-if="login_template.mobile.bottom_1.type == 'img'" :href="login_template.mobile.bottom_1.href" target="_blank"><img v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.mobile.bottom_1.data" v-bind:style="{ width: login_template.mobile.bottom_1.width }" /></a>
      <span v-if="login_template.mobile.bottom_1.type == 'text'" v-html="login_template.mobile.bottom_1.data" v-bind:style=" { color: login_template.mobile.bottom_1.color } "></span>
    </div>

    <div class="mobile_bottom_2" v-if="login_template.mobile.bottom_2.type != 'empty'">
      <div v-if="login_template.mobile.bottom_2.type == 'default'">
        <SupportLogin v-if="login_template.mobile.bottom_2.data == 'support'"></SupportLogin>
        <CatalogLogin v-if="login_template.mobile.bottom_2.data == 'catalog' && catalog"></CatalogLogin>
      </div>
      <img v-if="login_template.mobile.bottom_2.type == 'img' && login_template.mobile.bottom_2.href == ''" v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.mobile.bottom_2.data" v-bind:style="{ width: login_template.mobile.bottom_2.width }" />
      <a v-else-if="login_template.mobile.bottom_2.type == 'img'" :href="login_template.mobile.bottom_2.href" target="_blank"><img v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.mobile.bottom_2.data" v-bind:style="{ width: login_template.mobile.bottom_2.width }" /></a>
      <span v-if="login_template.mobile.bottom_2.type == 'text'" v-html="login_template.mobile.bottom_2.data" v-bind:style=" { color: login_template.mobile.bottom_2.color } "></span>
    </div>

    <div class="mobile_bottom_3" v-if="login_template.mobile.bottom_3.type != 'empty'" v-bind:style="{ marginTop: login_template.mobile.bottom_3.marginTop }">
      <div v-if="login_template.mobile.bottom_3.type == 'default'">
        <SupportLogin v-if="login_template.mobile.bottom_3.data == 'support'"></SupportLogin>
        <CatalogLogin v-if="login_template.mobile.bottom_3.data == 'catalog' && catalog"></CatalogLogin>
      </div>
      <img v-if="login_template.mobile.bottom_3.type == 'img' && login_template.mobile.bottom_3.href == ''" v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.mobile.bottom_3.data" v-bind:style="{ width: login_template.mobile.bottom_3.width }" />
      <a v-else-if="login_template.mobile.bottom_3.type == 'img'" :href="login_template.mobile.bottom_3.href" target="_blank"><img v-bind:src="base_url + '/assets/images/' + company_ID + '/' + login_template.mobile.bottom_3.data" v-bind:style="{ width: login_template.mobile.bottom_3.width }" /></a>
      <span v-if="login_template.mobile.bottom_3.type == 'text'" v-html="login_template.mobile.bottom_3.data" v-bind:style=" { color: login_template.mobile.bottom_3.color } "></span>
    </div>

  </div>
</template>

<script>
import SupportLogin from '@/components/SupportLogin.vue'
import CatalogLogin from '@/components/CatalogLogin.vue'

export default {
  name: 'Login',
  components: {
    SupportLogin,
    CatalogLogin
  },
  data() {
    return {
      loading: false,
      reset_password: false,
      change_password: false,
      passwordShow: false,
      newPasswordShow: false,
      newPasswordConfirmShow: false,
      email: '',
      password: '',
      access_email: '',
      new_password: '',
      password_confirm: '',
      status: 0,
      status_text: ''
    }    
  },
  computed: {
    base_url: function() {
      return this.$store.state.base_url
    },
    company_ID: function() {
      return this.$store.state.company_id
    },
    login_template: function() {
      return this.$store.state.login_template
    },
    background: function() {
      return {
        '--bg-image-desktop': 'url("' + this.base_url + '/assets/images/' + this.company_ID + '/' + this.login_template.desktop.background + '")',
        '--bg-image-mobile': 'url("' + this.base_url + '/assets/images/' + this.company_ID + '/' + this.login_template.mobile.background + '")'
      }
    },
    login_text: function() {
      return this.$store.state.infos.login_text
    },
    catalog: function() {
      return this.$store.state.infos.catalog
    }
  },
  methods: {
    login() {
      var self = this   
      if(self.email == '') {
        self.status = -1
        self.status_text = 'Inserisci la tua email'
      } else if(self.password == '') {
        self.status = -1
        self.status_text = 'Inserisci la password'
      } else {
        self.loading = true;
        this.axios.post(self.$store.state.base_url + '/auth', {
          email: self.email,
          password: self.password,
          access_email: self.access_email,
        }).then(function(response) {
          if(response.data.status == -1) {
            self.status = -1
            self.status_text = 'Email o password non validi'
          } else {
            self.$store.commit('setToken', response.data.token)
            self.$cookies.set('token', response.data.token)
            self.status = 1
            if(self.change_password) {
              self.status_text = 'Password aggiornata con successo'
            } else {
              self.status_text = 'Login effettuato con successo'
            }
            if(response.data.status == 2) {
              setTimeout(function() {
                self.change_password = true
                self.status = 0
                self.status_text = ''
              }, 2000)
            } else {
              if(response.data.admin == 1) {
                if(self.email == 'superadmin' && self.access_email == '') {
                  self.$store.commit('setSuperAdmin', true)
                }
                setTimeout(function() {
                  self.$router.push('admin-courses')
                }, 2000)
              } else {
                setTimeout(function() {
                  self.axios.get(
                    self.$store.state.base_url + '/api/student/coursesAndCertificatesCount',
                    {
                      headers: { Authorization: `Bearer ${self.$store.state.token}` }
                    }
                  ).then(function(response) {
                    self.$store.commit('setStudentInfos', response.data)
                    if(self.$store.state.infos.has_webinars) {
                      self.$router.push('webinars')
                    } else {
                      self.$router.push('courses')
                    }                    
                  })
                }, 2000)
              }
            }
          }
          self.loading = false;
        })
      }
    },
    sendResetPassword() {
      var self = this   
      if(self.email == '') {
        self.status = -1
        self.status_text = 'Inserisci la tua email'
      } else {
        self.loading = true;
        this.axios.post(self.$store.state.base_url + '/resetPassword', {
          email: self.email,
        }).then(function(response) {
          if(response.data == 1) {
            self.status = 1
            self.status_text = 'Ti abbiamo inviato una mail per il reset della password'
          } else {
            self.status = -1
            self.status_text = 'L\'email inserita non è registrata nella piattaforma';
          }
          self.loading = false;
        })
      }
    },
    changePassword() {
      var self = this   
      if(self.new_password == '') {
        self.status = -1
        self.status_text = 'Inserisci la password'
      } else if(self.password_confirm == '') {
        self.status = -1
        self.status_text = 'Inserisci la conferma della password'
      } else if(self.password_confirm != self.new_password) {
        self.status = -1
        self.status_text = 'Le password inserite non coincidono'
      } else {
        self.loading = true;
        this.axios.post(self.$store.state.base_url + '/api/utility/changePassword', {
          old_password: self.password,
          new_password: self.new_password
        },
        {
          headers: { Authorization: `Bearer ${self.$store.state.token}` }
        }
        ).then(function(response) {
           if(response.data == -1) {
            self.status = -1
            self.status_text = 'Errore, contatta l\'amministrazione'
          } else {
            self.password = self.new_password
            self.login()
          }
        })
      }
    },
  },
  watch: {
    email: function() {
      this.status = 0
      this.status_text = ''
    }
  },
  beforeCreate: function() {
    var self = this

    if(self.$store.state.token && self.$store.state.token.length > 20) {
      let token = window.parseJwt(self.$store.state.token)
      let admin = token.admin

      if(admin == 1) {
        self.$router.push('admin-courses')
      } else {
        self.$router.push('courses')
      }
    }
  },
  mounted: function() {
    let sa_token = this.$route.query.token
    if(sa_token != undefined) {
      let access_email = this.$route.query.access_email
      this.email = 'superadmin'
      this.password = sa_token
      this.access_email = access_email
      this.login()
    }
  }
}
</script>

<style type="text/css" scoped>
  .login {
    width: calc(100vw - 17px);
    height: 100vh;
    background-image: var(--bg-image-desktop);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: relative;
  }

  .desktop_top_left {
    position: absolute;
    top: 25px;
    left: 25px;
  }

  .desktop_top_left img {
    display: block;
  }

  .desktop_top_right {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  .desktop_top_right img {
    display: block;
  }

  .desktop_bottom_left {
    position: absolute;
    bottom: 25px;
    left: 25px;
  }

  .desktop_bottom_left img {
    display: block;
  }

  .desktop_bottom_right {
    position: absolute;
    bottom: 25px;
    right: 25px;
  }

  .desktop_bottom_right img {
    display: block;
  }

  .desktop_login_top {
    margin-bottom: 20px;
  }

  .desktop_login_top span {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  .desktop_login_top img {
    display: block;
  }

  .desktop_login_box_top {
    margin-bottom: 0px;
    margin-top: 30px;
  }

  .desktop_login_box_top span {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  .desktop_login_box_top img {
    display: block;
  }

  .desktop_login_bottom {
    margin-top: 20px;
  }

  .desktop_login_bottom span {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  .desktop_login_bottom img {
    display: block;
  }

  .mobile_top_1,
  .mobile_top_2,
  .mobile_top_3,
  .mobile_bottom_1,
  .mobile_bottom_2,
  .mobile_bottom_3 {
    display: none;
  }

  .login_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .login_box {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--color-main);
    border-radius: 6px;
  }

  .login_text {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  .login_content {
    display: flex;
    flex-direction: column;
    padding: 35px 30px;
    position: relative;
    width: 100%;
    border-radius: 6px;
  }

  .login_content label {
    font-size: 13px;
    font-weight: 600;
    color: var(--color-textmain);
    margin-bottom: 4px;
    position: relative;
  }

  .login_content label > i {
    position: absolute;
    right: 8px;
    bottom: -34px;
    font-size: 18px;
    cursor: pointer;
  }

  .login_content label > i.fa-eye-slash {
    right: 7px;
  }

  .login_content input {
    border: none;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 15px;
    color: #1A2441;
    font-weight: 600;
    outline: none;
    width: 100%;
    background: #fff;
    font-family: 'Raleway', sans-serif;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  }

  .login_content input::placeholder {
    font-size: 13px;
    color: #B0B0B0;
    font-weight: 600;
  }

  .login_content a {
    color: var(--color-textmain);
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
  }

  .login_content a:hover {
    text-decoration: underline;
  }

  .login_content button {
    background: var(--color-accent1);
    color: var(--color-textaccent1);
    outline: none;
    border: none;
    width: 100%;
    font-size: 17px;
    font-weight: 600;
    padding: 11px;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 20px;
  }

  .login_content button:hover {
    background: var(--color-accent1hover);
    color: var(--color-textaccent1hover);
  }

  .login_content .message {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -32px;
    margin: 0;
    padding: 7px 40px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    border-radius: 6px;
  }

  .login_content .message.error {
    background: #FF4140;
    color: #fff;
  }

  .login_content .message.success {
    background: #009900;
    color: #fff;
  }

  .login_content p {
    color: var(--color-textmain);
    font-size: 12px;
    margin-top: 20px;
    font-weight: 500;
  }

  .login_content p + p {
    margin-bottom: 18px;
  }

  .login_content.change_password p {
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: 15px;
    font-weight: 500;
  }

  @media (hover: none) {
    .login {
      width: 100vw;
    }
  }

  @media only screen and (max-width: 1199px) {
    .login_container {
      margin-top: -30px;
    }

    .login_content {
      padding: 40px 70px 40px 70px;
    }
  }

  @media only screen and (max-width: 959px) {
    .login {
      background-image: var(--bg-image-mobile);
      height: auto;
      min-height: 100vh;
      padding: 20px 0;
    }

    .login_container {
      margin-top: 20px;
    }

    .login_text {
      display: none;
    }

    .desktop_top_left,
    .desktop_top_right,
    .desktop_bottom_left,
    .desktop_bottom_right,
    .desktop_login_top,
    .desktop_login_bottom {
      display: none;
    }

    .mobile_top_1,
    .mobile_top_2,
    .mobile_top_3,
    .mobile_bottom_1,
    .mobile_bottom_2,
    .mobile_bottom_3 {
      display: block;
      max-width: 95%;
      margin: 0 auto;
      margin-top: 20px;
      text-align: center;
    }

    .mobile_top_1 img,
    .mobile_top_2 img,
    .mobile_top_3 img,
    .mobile_bottom_1 img,
    .mobile_bottom_2 img,
    .mobile_bottom_3 img {
      display: block;
      max-width: 95%;
      margin: 0 auto;
    }

    .mobile_top_1 span,
    .mobile_top_2 span,
    .mobile_top_3 span,
    .mobile_bottom_1 span,
    .mobile_bottom_2 span,
    .mobile_bottom_3 span {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      display: inline-block;
    }
  }

  @media only screen and (max-width: 599px) {
    .login_box {
      width: 95vw;
    }

    .login_content {
      padding: 30px 40px 40px;
    }
  }
</style>