<template>
  <div :style="colors">
    <router-view v-if="company_ID > 0" />
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    company_ID: function() {
      return this.$store.state.company_id
    },
    colors: function() {
      return {
        '--color-text': this.$store.state.colors.text,
        '--color-textaccent': this.$store.state.colors.textaccent,
        '--color-main': this.$store.state.colors.main,
        '--color-textmain': this.$store.state.colors.textmain,
        '--color-accent1': this.$store.state.colors.accent1,
        '--color-textaccent1': this.$store.state.colors.textaccent1,
        '--color-accent1hover': this.$store.state.colors.accent1hover,
        '--color-textaccent1hover': this.$store.state.colors.textaccent1hover,
        '--color-accent2': this.$store.state.colors.accent2,
        '--color-textaccent2': this.$store.state.colors.textaccent2,
        '--color-accent2hover': this.$store.state.colors.accent2hover,
        '--color-textaccent2hover': this.$store.state.colors.textaccent2hover
      }
    },
  },
  mounted: function() {
    var self = this

    self.axios.get(self.$store.state.base_url + '/infos').then(function(response) {
      self.$store.commit('setCompanyID', response.data.ID)
      self.$store.commit('setCompanyName', response.data.name)
      self.$store.commit('setCompanyLogo', self.$store.state.base_url + '/assets/images/' + self.$store.state.company_id + '/logo.png')
      self.$store.commit('setLoginTemplate', response.data.login_template)
      if(response.data.login_template_english_test != undefined) {
        self.$store.commit('setLoginTemplateEnglishTest', response.data.login_template_english_test)
      }
      self.$store.commit('setColors', response.data.colors)
      self.$store.commit('setInfos', response.data)
      document.title = response.data.name + ' | Lezione-online';
    })

    if(self.$cookies.get('token') && self.$cookies.get('token').length > 20) {
      let token = window.parseJwt(self.$cookies.get('token'));
      let admin = token.admin;
      let confirmed = token.confirmed;
      let user_id = token.user_id;

      if(confirmed) {
        self.$store.commit('setToken', self.$cookies.get('token'));

        if(!admin) {
          self.axios.get(
            self.$store.state.base_url + '/api/student/coursesAndCertificatesCount',
            {
              headers: { Authorization: `Bearer ${self.$store.state.token}` }
            }
          ).then(function(response) {
            self.$store.commit('setStudentInfos', response.data);
          })
        }

        if(user_id == 0) {
          self.$store.commit('setSuperAdmin', true);
        }
      }
    } else {
      if(self.$route.path != '/') {
        self.$router.push('/');
      }
    }
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&display=swap');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    font-feature-settings: 'lnum' 1;
    overflow-y: scroll;
    color: var(--color-text);
  }

  #app {
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
  }

  .noselect {
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
  }

  .vfm--overlay {
    background: rgba(26,36,65, 0.8) !important;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    min-width: 150px;
    bottom: 140%;
    left: 50%;
    margin-left: -75px;
    background-color: #1A2441;
    color: #fff;
    font-size: 11px;
    line-height: 11px;
    font-weight: 500;
    text-align: center;
    padding: 5px 2px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #1A2441 transparent transparent transparent;
  }

  .tooltip_right_blue {
    position: relative;
    display: inline-block;
  }

  .tooltip_right_blue .tooltiptext {
    visibility: hidden;
    min-width: 160px;
    top: calc(50% - 10px);
    left: calc(100% + 10px);
    background-color: #6895f7;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    text-align: center;
    padding: 5px 2px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
  }

  @media only screen and (min-width: 1200px) {

    .tooltip_right_blue:hover .tooltiptext {
      visibility: visible;
    }
  }

  @media (hover: none) {
    body {
      overflow-y: auto;
    }
  }
</style>
