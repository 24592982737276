<template>
	<div class="support" @click="support = true">
      <i class="fa-solid fa-circle-question"></i>&nbsp; ASSISTENZA
    </div>
    <vue-final-modal v-model="support" classes="support_login_modal" content-class="support_login_modal_box">
      <div class="modal_title">
        <span>ASSISTENZA</span>
        <button class="modal_close" @click="support = false">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="modal_content">
        <div class="support_modal_text">Per problemi tecnici di accesso alla piattaforma, inviare una mail a <a href="mailto:info@lezione-online.it">info@lezione-online.it</a> dettagliando il problema e indicando come oggetto della mail: Problemi tecnici piattaforma {{ company_name }}</div>
      </div>
    </vue-final-modal>
</template>

<script>  
export default {
	name: 'SupportLogin',
  data() {
    return {
      support: false
    }
  },
  computed: {
    company_name: function() {
      return this.$store.state.company_name
    },
  }
}
</script>

<style type="text/css" scoped>
  .support {
    background: var(--color-accent1);
    color: var(--color-textaccent1);
    padding: 12px 24px;
    text-align: center;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;
  }

  .support:hover {
    background: var(--color-accent1hover);
    color: var(--color-textaccent1hover);
  }
</style>

<style type="text/css">
  .support_login_modal {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
  }

  .support_login_modal_box {
    width: 600px;
    background: #fff;
    box-shadow: 0 10px 30px rgba(0,0,0,.25);
  }

  .support_login_modal .modal_title {
    background: var(--color-accent1);
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .support_login_modal .modal_title span {
    color: var(--color-textaccent1);
    font-size: 18px;
    font-weight: 600;
  }

  .support_login_modal .modal_close {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .support_login_modal .modal_close > i {
    color: var(--color-textaccent1);
    font-size: 22px;
  }

  .support_login_modal .modal_content {
    padding: 35px 25px 40px;
  }

  .support_login_modal .support_modal_text {
    font-size: 15px;
  }

  @media only screen and (max-width: 959px) {
    .support_login_modal_box {
      width: 90%;
    }
  }
</style>